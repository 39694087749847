// get environment based on where we're running
import collections from './collections.json';
import config from './config.json';
import { IFirebaseEnvironment } from './environment.types';

let environment: IFirebaseEnvironment = {
  collections: collections,
  firebase: {
    client: {
      apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
      appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
      authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
      databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
      measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
      messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
      projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
      storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
    },
    enableLogging: Boolean(process.env.NEXT_PUBLIC_FIREBASE_ENABLE_LOGGING),
    remoteConfig: {
      defaultConfig: config,
      minimumFetchIntervalMillis:
        Number(process.env.NEXT_PUBLIC_FIREBASE_REMOTE_CONFIG_FETCH_INTERVAL) ||
        null,
    },
  },
};

if (process.env.FIRESTORE_EMULATOR_HOST) {
  console.log('Using emulator environment...');
  // override the databaseURL and projectId for the client connection,
  // since there's an error with firebase:
  // https://github.com/firebase/firebase-tools/issues/3309
  // environment.firebase.client.projectId = "trustscout-emulators";
  // environment.firebase.client.databaseURL =
  //   "http://localhost:8080/?ns=trustscout-emulators";
}

export default environment;
