import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/remote-config';
export const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp;
export const STORAGE_STATE_CHANGED = firebase.storage.TaskEvent.STATE_CHANGED;
export type DocumentReferenceType = firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
export type Timestamp = firebase.firestore.Timestamp;
export const FieldValue = firebase.firestore.FieldValue;

import environment from './environments';
import { sluggy } from 'lib/helpers';

let remoteConfig;

if (!firebase.apps.length) {
  firebase.initializeApp(environment.firebase.client);
  // turn on logging for development
  // if (environment.firebase.enableLogging) {
  //   firebase.firestore.setLogLevel('debug');
  // }

  if (process.env.NEXT_PUBLIC_USE_EMULATORS) {
    // Point to the Auth emulator running on localhost.
    firebase.auth().useEmulator('http://localhost:9099/');
    // Point to the Firestore emulator running on localhost.
    firebase.firestore().useEmulator('localhost', 8080);

    // use the emulator if we're running locally
    console.log(
      'Firebase local emulator configuration detected! Connected to emulators.'
    );
  }
}

/**
 * return the remote config, initializing it first if it hasn't been set up already
 *
 * @returns firebase remote config
 */
const getRemoteConfig = () => {
  if (!remoteConfig) {
    // attempt to initialize remote config
    remoteConfig = firebase.remoteConfig();
    if (environment.firebase.remoteConfig.minimumFetchIntervalMillis) {
      // override the fetch interval, if defined
      remoteConfig.settings = {
        fetchTimeoutMillis: remoteConfig.settings.fetchTimeoutMillis,
        minimumFetchIntervalMillis:
          environment.firebase.remoteConfig.minimumFetchIntervalMillis,
      };
    }
    // set the remote config defaults
    remoteConfig.defaultConfig =
      environment.firebase.remoteConfig.defaultConfig;

    // attempt to activate remote config values
    remoteConfig.fetchAndActivate().catch((error) => {
      console.error(error);
    });
  }
  return remoteConfig;
};

export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
export const functions = firebase.functions()
export { getRemoteConfig };
export default firebase;

/**
 * Converts a firestore document to JSON
 * @param  {DocumentSnapshot} doc
 */
export function docToJSON(doc) {
  try {
    const data = doc.data() || {};

    return {
      ...data,
      // Firestore timestamp NOT serializable to JSON. Must convert to milliseconds
      createdAt: data.createdAt?.toMillis() || null,
      updatedAt: data.updatedAt?.toMillis() || null,
    };
  } catch (error) {
    console.error("Error converting document to JSON:", error);
    // You can also choose to rethrow the error or handle it in another way
    throw new Error("Error converting document to JSON");
  }
}


export async function generateSlug(companyName: string): Promise<string> {
  try {
    // Check if the slug already exist
    const snapshots = await db
      .collection('companies')
      .where('slug', '==', sluggy(companyName))
      .get();

    const slug = snapshots.empty
      ? sluggy(companyName)
      : sluggy(companyName, { unique: true });

    return slug;
  } catch (error) {
    console.log("err",error)
    console.error(error);
  }
}
